<template>
    <div class="main-wrapper">
<!--        <div class="main-top">-->
<!--            <div class="top-item">-->
<!--                <div class="item">-->
<!--                    <span class="item-text">课程</span>-->
<!--                    <el-cascader v-model="chapterId" style="width: 200px" :props="keyObj" :options="chapterList"-->
<!--                                 @change="changeChapter" placeholder="课程名称/章名称/节"></el-cascader>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
      <el-scrollbar class="main-left" :native="false">
        <div class="left-box">
          <div class="left-item" @click="changeChapter(0)"><span :class="chapterId ==  0? 'span-active text-overflow' : 'text-overflow'">全部课程</span></div>
          <div class="left-item" @click="changeChapter(item.course_id)" v-for="item in chapterList"><span :class="item.course_id === chapterId ? 'span-active text-overflow' : 'text-overflow'">{{item.course_name}}</span></div>
        </div>
      </el-scrollbar>
      <div class="main-tight">
        <el-table :data="examList" class="customTable" style="width: 100%; flex: 1" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
          <el-table-column prop="theory_test_paper_name" label="试卷名称"></el-table-column>
          <el-table-column prop="theory_test_paper_score" label="分值" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.theory_test_paper_score}}分</span>
            </template>
          </el-table-column>
          <el-table-column prop="total_train_num" label="训练次数" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.total_train_num}}次</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link :underline="false" @click="resetBtn(scope.row.theory_test_paper_id,scope.row.course_id,scope.row.chapter_id,scope.row.node_id)">重置训练</el-link>
              <el-link type="success" :underline="false">
                <router-link class="a-link" :to="{path: '/student/trainCenter/startTrainExam', query: {id: scope.row.theory_test_paper_id,course_id:scope.row.course_id,chapter_id:scope.row.chapter_id,lesson_id:scope.row.node_id}}">开始训练</router-link>
              </el-link>
              <el-link type="primary" :underline="false">
                <router-link class="a-link" :to="{path: '/student/trainCenter/lookAnswerDetail', query: {id: scope.row.theory_test_paper_id,course_id:scope.row.course_id,chapter_id:scope.row.chapter_id,lesson_id:scope.row.node_id,trainName:scope.row.theory_test_paper_name}}">答题详情</router-link>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
      </div>
    </div>
</template>

<script>
    import { getTeaSchoolCourse, trainTheoryListStudent, trainResetAnswerTheoryPaper } from '@/utils/apis.js'
    export default {
        data() {
            return {
                chapterId: [],
                chapterList: [],
                examList: [
                    {}
                ],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                keyObj: {
                    label: 'name',
                    value: 'id',
                    children: 'children'
                },
            }
        },
        mounted() {
            this.getChapterList()
        },
        methods: {
            getList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                if (this.chapterId) {
                    params.course_id = this.chapterId
                }
                trainTheoryListStudent(params).then((res) => {
                    this.examList = res.data.list
                    this.listPages.total = res.data.total
                })
            },
            changeChapter(id) {
              this.chapterId = id;
              this.listPages.currentPageNum = 1
              this.getList()
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            getChapterList() {
                getTeaSchoolCourse().then((res) => {
                    // let arrTmp = []
                    // if (res.data.length > 0) {
                    //     res.data.forEach(item => {
                    //         let tmp = {
                    //             id: item.course_id,
                    //             name: item.course_name,
                    //             children: []
                    //         }
                    //         if (item.data.length > 0) {
                    //             item.data.forEach(item1 => {
                    //                 let tmp1 = {
                    //                     id: item1.id,
                    //                     name: item1.name,
                    //                     children: []
                    //                 }
                    //                 tmp.children.push(tmp1)
                    //                 if (item1.child.length > 0) {
                    //                     item1.child.forEach(item2 => {
                    //                         let tmp2 = {
                    //                             id: item2.id,
                    //                             name: item2.name,
                    //                         }
                    //                         tmp1.children.push(tmp2)
                    //                     })
                    //                 } else {
                    //                     delete tmp1.children
                    //                 }
                    //             })
                    //         } else {
                    //             delete tmp.children
                    //         }
                    //         arrTmp.push(tmp)
                    //     })
                    //   this.chapterId = [arrTmp[0].id,arrTmp[0].children[0].id,arrTmp[0].children[0].children[0].id]
                    // }
                    this.chapterList = res.data;
                    this.getList()
                })
            },
            resetBtn(id,course_id,chapter_id,node_id) {
              const confirmText = ['1.重置训练将会清空当前训练数据，学生可以重新开始训练','2.每一次训练成绩详情可在答题详情进行查看'];
              const newDatas = [];
              const h = this.$createElement;
              for (let i in confirmText) {
                newDatas.push(h('p', null, confirmText[i]))
              }
                this.$confirm('提示消息', {
                    title:'提示信息',
                    message: h('div', null, newDatas),
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                    let params = {
                        theory_test_paper_id: id,
                        course_id:course_id,
                        chapter_id:chapter_id,
                        lesson_id:node_id
                    }
                    trainResetAnswerTheoryPaper(params).then((res) => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch((err) => {})
                }).catch(() => {
                    this.$message.info('已取消重置');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        padding: 0 20px;
    }
    .main-left{
      z-index: 98;
      //background: #F6F7FC;
      position: relative;
      top: -20px;
      height: calc(100% + 20px);
      border-right: 20px solid #F6F7FC;
      ::v-deep .el-scrollbar__wrap{
        overflow-x: hidden;
      }
      .left-box{
        width: 210px;
        display: flex;
        flex-direction: column;
        .left-item{
          width: 100%;
          cursor: pointer;
          line-height: 1;
          margin: 16px 0;
          display: flex;
          span{
            line-height: 1;
            color: #666;
            font-size: 14px;
          }
          .span-active{
            color: #1AB163;
          }
        }
      }
    }
    .main-tight{
      flex: 1;
      width: 1%;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
    .a-link {
        color: inherit;
    }
</style>